import { CircularProgress } from "@mui/material";

type Props = {
  className?: string;
  size?: number;
};
function Loader({ className, size = 30 }: Props) {
  return (
    <div className={className}>
      <CircularProgress size={size} className="text-primary-color mt-1" />
    </div>
  );
}

export default Loader;
