function PageLoader() {
  return (
    <div className="h-screen flex items-center justify-center fixed w-screen top-0 left-0">
      <div className="flex items-center gap-3">
        <figure className="m-0 p-0 animate-hue">
          <img src="/logo.svg" alt="peerlyfe" height={40} />
        </figure>
      </div>
    </div>
  );
}

export default PageLoader;

/** Animated Logo with spinner around the small logo */

// import { CircularProgress } from "@mui/material";
// import styled from "styled-components";

// const Wrapper = styled.div`
//   .loader {
//     border: 4px solid #f3f3f3;
//     /* Light grey */
//     border-top: 4px solid var(--primary-color);
//     /* Dark Green */
//     border-radius: 50%;
//     width: 60px;
//     height: 60px;
//     animation: spinloader 1s linear infinite;
//     position: absolute;
//   }
//   position: relative;

//   .loader img {
//     height: 60px;
//     width: 60px;
//     animation: spinlogo 1s linear infinite;
//   }

//   @keyframes spinloader {
//     0% {
//       transform: rotate(0deg);
//     }

//     100% {
//       transform: rotate(360deg);
//     }
//   }

//   @keyframes spinlogo {
//     0% {
//       transform: rotate(360deg);
//     }

//     100% {
//       transform: rotate(0deg);
//     }
//   }
// `;

// function PageLoader() {
//   return (
//     <Wrapper className="h-screen flex items-center justify-center fixed w-screen top-0 backdrop-blur-lg left-0">
//       <div className="text-center flex items-center gap-3">
//         {/* <figure className="m-0 p-0 animate-hue">
//           <img src="/logo.svg" alt="peerlyfe" height={40} />
//         </figure> */}

//         {/* <CircularProgress size="1.5rem" className="text-primary-color" /> */}
//         <div className="loader">
//           <img alt="logo-loader" src="/favicon.svg" />
//         </div>
//       </div>
//     </Wrapper>
//   );
// }

// export default PageLoader;
