import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconButton, Zoom } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavItem from "./NavLink";
import Sidebar from "./Sidebar";
import ProfileMenu from "./ProfileMenu";
import LogoutPrompt from "./LogoutPrompt";
import { breakpoints } from "helpers/hooks/useResponsive";
import { useAuth } from "context/AuthContext";
import { NAVS } from "consts/NAVS";
import { CONFIG } from "consts/CONFIG";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 5.635rem;
  @media ${breakpoints.mobile} {
    padding: 24px;
  }
  .logo:hover {
    transform: scale(1.08);
    transition: all 0.5s ease;
  }
`;
const Header = () => {
  const { signout } = useAuth();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false);
  const toggleDrawer = () => setOpenDrawer(!openDrawer);
  const toggleProfileMenu = () => setOpenProfileMenu(!openProfileMenu);

  const [showLogoutPrompt, setShowLogoutPrompt] = useState<boolean>(false);
  const toggleLogoutPrompt = () => {
    setShowLogoutPrompt((prev) => !prev);
  };

  const onLogout = () => {
    toggleLogoutPrompt();
    signout();
  };

  return (
    <>
      <Wrapper className="md:gap-8 gap-4 md:px-10 px-4">
        <IconButton className="-ml-1 md:hidden" onClick={toggleDrawer}>
          <MenuIcon className="md:hidden cursor-pointer" />
        </IconButton>

        <Zoom in>
          <Link to="/" className="flex items-center">
            <img
              src="/logo.svg"
              alt="jackfruit-logo"
              className="logo cursor-pointer"
            />
          </Link>
        </Zoom>

        <div className="md:flex hidden items-center gap-8">
          <div className="divider h-5 w-[1.5px] bg-lightgray" />

          {NAVS.map((nav) => (
            <NavItem nav={nav} key={nav.name} />
          ))}
        </div>

        <div
          onClick={toggleProfileMenu}
          className="relative flex items-center p-[2px] rounded-full w-[80px] border border-solid border-lightgray hover:border-lightblack transition ease-in-out duration-300 ml-auto cursor-pointer"
        >
          <img
            alt="user-profile"
            src={CONFIG.DEFAULT_COMPANY_ICON}
            className="h-11 w-11 rounded-full object-cover"
          />
          <ArrowDown className="m-auto stroke-darkGray" />
          {openProfileMenu && (
            <ProfileMenu
              onLogout={toggleLogoutPrompt}
              toggle={toggleProfileMenu}
            />
          )}
        </div>

        <LogoutPrompt
          isOpen={showLogoutPrompt}
          toggle={toggleLogoutPrompt}
          logout={onLogout}
        />
      </Wrapper>

      <div className="md:hidden">
        <Sidebar isOpen={openDrawer} toggleDrawer={toggleDrawer} />
      </div>
    </>
  );
};

export default Header;
