import React from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import styled from "styled-components";
import INPUT_MAXLENGTH from "consts/INPUT_MAXLENGTH";
import { ReactComponent as Search } from "assets/icons/search.svg";

const Input = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiInputBase-root {
    border: 1px solid #d9d9e7;
    height: 50px;
    max-width: 100%;
    border-radius: 6px;
  }
  .MuiFormHelperText-root {
    margin: 0.25rem 0 0 0;
  }
`;

type Props = TextFieldProps & {
  label?: string;
  type?: string;
  maxLength?: number | null;
  className?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

const SearchBox = (
  {
    label,
    type = "text",
    maxLength = INPUT_MAXLENGTH.DEFAULT,
    className,
    startIcon = <Search />,
    endIcon,
    ...props
  }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <div className={className}>
      <Input
        {...props}
        fullWidth
        inputRef={ref}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon && endIcon,
        }}
      />
    </div>
  );
};

export default React.forwardRef(SearchBox);
