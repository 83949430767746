import { Navigate, useLocation } from "react-router-dom";
import { getToken } from "helpers/storage";

function RequireAuth({ children }: { children: JSX.Element }) {
  const isLoggedIn = getToken();
  const location = useLocation();
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else {
    return children;
  }
}

export default RequireAuth;
