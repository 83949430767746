import { Button as MuiButton, CircularProgress } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import styled, { css } from "styled-components";
import { myTheme } from "styles/theme";

export const Button = styled(MuiButton)<{
  // rounded and shadow are custom props that is going to be added to the dom, as they are not boolean by default so allowing to accept number either 0 or 1. where 0 is not rounded and 1 is rounded.
  rounded?: boolean | 0 | 1;
  shadow?: boolean | 0 | 1;
}>`
  font-family: ${myTheme.font.secondary};
  font-size: 1rem;
  padding: 0.75rem 2rem;
  text-transform: initial;
  border-radius: 50px;
  transition: all 0.5s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  ${({ color = "primary", variant }) => {
    /** Adding the outlined one, it will be uesfull in other places as well
     * otherwise we need to add more tailwind classes to achive this */
    if (variant === "outlined") {
      return css`
        background-color: transparent;
        color: var(--light-black);
        border: 1px solid #8f8f8f;
        &:hover {
          border: 1px solid ${myTheme.colors.primary};
          background-color: ${myTheme.colors.primary};
          color: white;
        }
      `;
    }

    if (color === "primary" && variant !== "text") {
      return css`
        background-color: ${myTheme.colors.primary};
        color: #fff;
        &:hover {
          background-color: ${myTheme.colors.primary};
          filter: brightness(1.1) contrast(1.2);
          color: #fff;
        }
      `;
    }

    if (color === "secondary" && variant !== "text") {
      return css`
        color: var(--primary-color);
        background-color: var(--blue-tint);
        &:hover {
          background-color: var(--primary-color);
          color: #fff;
        }
      `;
    }
    if (variant === "text") {
      return css`
        background-color: transparent;
        color: var(--primary-color);
        padding: 0;
        &:hover {
          color: inherit;
          box-shadow: none;
          transform: none;
          background-color: transparent;
        }
      `;
    }
  }}
  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.09);
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${myTheme.colors.primary}!important;
      color: white !important;
      opacity: 0.75;
    `}
`;

type ButtonPropsext = ButtonProps & {
  children: React.ReactNode;
  loading?: boolean;
};

export const LoadingButton = ({
  children,
  loading,
  ...props
}: ButtonPropsext) => {
  return (
    <Button disabled={loading} {...props}>
      {children}
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: "white",
            marginLeft: "1rem",
          }}
        />
      )}
    </Button>
  );
};
