import React from "react";
import Table from "@mui/material/Table";
import { useQuery } from "@tanstack/react-query";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import SearchBox from "components/SearchBox";
import EnhancedTableHead from "components/table/EnhancedTableHead";
import StyledTable from "components/table/StyledTable";
import ProfileAvatar from "components/ProfileAvatar";
import Loader from "components/loaders/Loader";
import NoDataFound from "components/NoDataFound";
import useDebounce from "helpers/hooks/useDebounce";
import useTableScrollFade from "helpers/hooks/useTableScrollFade";
import users from "helpers/http/users";
import { SortOrderTypes } from "helpers/types/general.types";
import { DELETED_USERS_CELLS } from "./TABLE_CELLS";
import { TABLE_RECORDS_PER_PAGE } from "consts/CONFIG";

const DeletedUsers = () => {
  const [order, setOrder] = React.useState<SortOrderTypes>("ASC");
  const [rows, setRows] = React.useState<any>([]);
  const [page, setPage] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>("");

  const debouncedSearch = useDebounce(search, 500);

  const { data, isLoading, isRefetching } = useQuery(
    ["deleted-users", page, debouncedSearch, order],
    () =>
      users.getUsersList({
        page,
        limit: TABLE_RECORDS_PER_PAGE,
        keyword: debouncedSearch,
        status: "deleted-users",
        sort: [
          {
            key: "APPLICATION",
            value: order,
          },
        ],
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useTableScrollFade(data?.data?.userData);

  React.useEffect(() => {
    setPage(1);
  }, [debouncedSearch, order]);

  React.useEffect(() => {
    if (data?.data?.userData) {
      setRows(data?.data?.userData);
    }
  }, [data?.data?.userData]);

  const handleRequestSort = () => {
    setOrder((prev) => (prev === "ASC" ? "DESC" : "ASC"));
  };

  const onPageChange = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div className="w-full">
      <SearchBox
        className="md:w-[410px] w-full max-w-full"
        placeholder="Search"
        endIcon={isRefetching && <Loader size={24} />}
        onChange={handleSearch}
      />
      <div className="relative mt-4">
        <div id="faded-effect" />
        <StyledTable>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            className="overflow-hidden"
          >
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={DELETED_USERS_CELLS}
              order={order}
              // orderBy={orderBy}
            />

            <TableBody>
              {rows?.length > 0 &&
                rows.slice(0, TABLE_RECORDS_PER_PAGE).map((row: any) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.user_id}>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        className="px-4 w-[25%]"
                      >
                        <div className="flex items-center gap-3">
                          <ProfileAvatar
                            imageurl={row.user_image}
                            name={row.first_name + " " + row.last_name}
                            className="h-10 w-10 rounded-full object-cover"
                          />
                          <div className="text-base font-medium">
                            {row.first_name + " " + row.last_name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="px-4 text-base w-[20%]">
                        <a
                          href={`mailto:${row.u_email_id}`}
                          target="blank"
                          className="text-inherit no-underline hover:text-primary-color"
                        >
                          {row.u_email_id}
                        </a>
                      </TableCell>
                      <TableCell className="px-4 text-base w-[20%] font-medium">
                        +{row.phone_number}
                      </TableCell>
                      <TableCell className="px-4 text-base w-[15%] font-medium">
                        {row.application_count?.toLocaleString()}
                      </TableCell>
                      <TableCell className="px-4 text-base w-[15%] font-medium">
                        {row.contact_count?.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          {data?.data?.total > TABLE_RECORDS_PER_PAGE && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              page={page - 1}
              rowsPerPage={TABLE_RECORDS_PER_PAGE}
              count={data?.data?.total}
              onPageChange={onPageChange}
            />
          )}
        </StyledTable>
      </div>
      {isLoading && <Loader className="flex items-center justify-center" />}
      {!isLoading && !isRefetching && !rows?.length && (
        <NoDataFound
          noDataText="No record found"
          containerClassName="flex flex-col min-h-[65vh] justify-center items-center"
        />
      )}
    </div>
  );
};

export default DeletedUsers;
