import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const Dashboard = lazy(() => import("modules/dashboard"));
export default function DashboardPage() {
  return (
    <SuspenseWrapper>
      <Dashboard />
    </SuspenseWrapper>
  );
}
