import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const ForgotPasswordPage = lazy(() => import("modules/forgot-password"));
export default function ForgotPassword() {
  return (
    <SuspenseWrapper>
      <ForgotPasswordPage />
    </SuspenseWrapper>
  );
}
