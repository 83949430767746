import ActiveUsers from "./ActiveUsers";
import DeletedUsers from "./DeletedUsers";

const USERS_ROUTES = [
  {
    id: "active",
    label: "Active Users",
    component: ActiveUsers,
  },
  {
    id: "deleted",
    label: "Deleted Users",
    component: DeletedUsers,
  },
];

export default USERS_ROUTES;
