import toast from "react-hot-toast";
import moment from "moment";

export const showMsg = (msg: string) => {
  toast.dismiss();
  toast.success(msg);
};
export const showError = (msg: string) => {
  toast.dismiss();
  toast.error(msg);
};

/**
 *
 * @param date : string
 * @param format : string
 * @returns This function converts UTC date to local date
 */
export const getLocalDate = (date: string, format?: string) => {
  if (format === "fromNow") {
    return moment.utc(date).local().fromNow();
  }
  return moment
    .utc(date)
    .local()
    .format(format || "MMM DD, YYYY");
};

export const isEmptyObject = (obj: Object) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const formatJobType = (jobType: string) => {
  return jobType.charAt(0).toUpperCase() + jobType.slice(1).toLowerCase();
};

export function urlify(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a target="blank" href="' + url + '">' + url + "</a>";
  });
}
