import styled from "styled-components";
import { TableContainer } from "@mui/material";

const StyledTable = styled(TableContainer)<{ hoverEnabled?: boolean }>`
  position: relative;
  .MuiTableRow-root:hover {
    background: ${({ hoverEnabled = true }) =>
      hoverEnabled ? "var(--secondary-color)" : "transparent"};
  }
  tbody:before {
    line-height: 6px;
    content: ".";
    color: white; /* bacground color */
    display: block;
  }
  .MuiTableCell-sizeMedium {
    font-size: 14px;
    font-family: "Outfit", sans-serif;
  }
  .MuiTableCell-root {
    div,
    .MuiButton-text {
      font-size: 14px;
      font-family: "Outfit", sans-serif;
    }
  }
`;

export default StyledTable;
