import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import {
  ForgotPasswordRequestType,
  LoginRequestType,
  ResetPasswordRequestType,
} from "helpers/types/auth.types";

const auth = {
  login(data: LoginRequestType) {
    return apiClient
      .post(API.LOGIN, data)
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  forgotPassword(data: ForgotPasswordRequestType) {
    return apiClient
      .post(API.FORGOT_PASSWORD, data)
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  resetPassword(data: ResetPasswordRequestType) {
    return apiClient
      .post(API.RESET_PASSWORD, data)
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  viewAsUser(user_id: string) {
    return apiClient
      .post(API.VIEW_AS_USER, {
        user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default auth;
