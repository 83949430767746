import React from "react";
import { Drawer } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { NAVS } from "consts/NAVS";

type Props = {
  toggleDrawer: () => void;
  isOpen: boolean;
};

const Wrapper = styled.div`
  .active {
    font-weight: bold;
  }
`;

const Sidebar = ({ toggleDrawer, isOpen }: Props) => {
  return (
    <React.Fragment>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer}>
        <Wrapper className="min-w-[290px] p-4">
          <Link to="/" className="flex items-center mb-4 py-4">
            <img src="/logo.svg" alt="jackfruit-logo" />
          </Link>
          {NAVS.map((nav) => (
            <div key={nav.name} className="p-2">
              <NavLink
                className={({ isActive }) =>
                  `text-lightblack no-underline text-black ${
                    isActive ? "active" : ""
                  }`
                }
                to={nav.path}
              >
                {nav.name}
              </NavLink>
            </div>
          ))}
        </Wrapper>
      </Drawer>
    </React.Fragment>
  );
};

export default Sidebar;
