import { Routes, Route, Navigate } from "react-router-dom";
import Login from "pages/login";
import ForgotPassword from "pages/forgot-password";
import ResetPassword from "pages/reset-password";
import Page404 from "pages/404";
import Dashboard from "pages/dashboard";
import Users from "pages/users";
import Applications from "pages/applications";
import ApplicationDetails from "pages/application-details";
import UserDetails from "pages/user-details";
import PageLayout from "layouts/PageLayout";
import AuthLayout from "layouts/AuthLayout";
import { getToken } from "helpers/storage";
import { ROUTES } from "./routes";
import USERS_ROUTES from "modules/users/USERS_ROUTES";

function AppRouter() {
  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={ROUTES.APPLICATIONS} element={<Applications />} />
        <Route
          path={ROUTES.APPLICATION_DETAILS}
          element={<ApplicationDetails />}
        />
        <Route path={ROUTES.USER_DETAILS} element={<UserDetails />} />
        <Route path={ROUTES.USERS} element={<Users />}>
          {USERS_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route index element={<Navigate replace to={USERS_ROUTES[0].id} />} />
        </Route>
      </Route>

      <Route element={<AuthLayout />}>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      </Route>
      <Route path={ROUTES.NOT_FOUND} element={<Page404 />} />
      <Route path="" element={<Navigate to={ROUTES.DASHBOARD} />} />
      <Route path="*" element={<Navigate replace to={ROUTES.NOT_FOUND} />} />
      <Route
        index
        element={
          getToken() ? (
            <Navigate to={ROUTES.DASHBOARD} />
          ) : (
            <Navigate to={ROUTES.DASHBOARD} />
          )
        }
      />
    </Routes>
  );
}

export default AppRouter;
