import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, LoadingButton } from "components/buttons";
import UIModal from "components/UIModal";
import users from "helpers/http/users";
import { showError, showMsg } from "helpers/misc";

type Props = {
  toggle: () => void;
  onDelete: () => void;
  deleteMemberState: {
    userId: string;
    name: string;
    show: boolean;
  };
};

const DeleteUserPrompt = ({ deleteMemberState, toggle, onDelete }: Props) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(users.deleteUser, {
    onSuccess: (data) => {
      showMsg(data.message);
      queryClient.invalidateQueries(["active-users"]);
      onDelete && onDelete();
      toggle();
    },
    onError: (error) => {
      showError(error + "");
    },
  });

  /** @function This will uninvite the invitee */
  const handleRemove = () => {
    deleteMemberState.userId && mutate(deleteMemberState.userId);
  };

  return (
    <form>
      <UIModal
        papperProps={{
          component: "form",
          onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          },
        }}
        show={deleteMemberState.show}
        toggle={toggle}
        title={""}
      >
        <div className="font-normal text-center">
          Are you sure you want to delete{" "}
          <span className="font-bold capitalize">{deleteMemberState.name}</span>
          ?
        </div>
        <div className="flex items-center gap-3 mt-7 justify-center flex-wrap">
          <Button
            color="inherit"
            rounded={1}
            variant="outlined"
            className="text-black"
            onClick={toggle}
          >
            Go back
          </Button>
          <LoadingButton onClick={handleRemove} loading={isLoading}>
            Yes, remove
          </LoadingButton>
        </div>
      </UIModal>
    </form>
  );
};

export default DeleteUserPrompt;
