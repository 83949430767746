import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const ResetPasswordPage = lazy(() => import("modules/reset-password"));
export default function ResetPassword() {
  return (
    <SuspenseWrapper>
      <ResetPasswordPage />
    </SuspenseWrapper>
  );
}
