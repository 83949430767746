export const ACTIVE_USERS_CELLS = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    allowSort: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    allowSort: false,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    allowSort: false,
  },
  {
    id: "application_count",
    numeric: true,
    disablePadding: false,
    label: "Applications",
    allowSort: true,
  },
  {
    id: "networks",
    numeric: false,
    disablePadding: false,
    label: "Networks",
    allowSort: false,
  },
  {
    id: "joined_on",
    numeric: false,
    disablePadding: false,
    label: "Joined on",
    allowSort: false,
  },
  {
    id: "view_as_user",
    numeric: false,
    disablePadding: false,
    label: "",
    allowSort: false,
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: false,
    label: "",
    allowSort: false,
  },
];

export const DELETED_USERS_CELLS = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    allowSort: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    allowSort: false,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    allowSort: false,
  },
  {
    id: "application_count",
    numeric: true,
    disablePadding: false,
    label: "Applications",
    allowSort: true,
  },
  {
    id: "networks",
    numeric: false,
    disablePadding: false,
    label: "Networks",
    allowSort: false,
  },
];
