import styled from "styled-components";
import { Link } from "react-router-dom";
import { Zoom } from "@mui/material";
import { breakpoints } from "helpers/hooks/useResponsive";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 5.635rem;
  @media ${breakpoints.mobile} {
    padding: 24px;
  }
  .logo:hover {
    transform: scale(1.08);
    transition: all 0.5s ease;
  }
`;

const AuthHeader = () => {
  return (
    <Wrapper className="md:px-12 px-4">
      <Zoom in>
        <Link to="/">
          <img
            src="/logo.svg"
            alt="jackfruit-logo"
            className="logo cursor-pointer"
          />
        </Link>
      </Zoom>
    </Wrapper>
  );
};

export default AuthHeader;
