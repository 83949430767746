import * as API from "consts/http/API_URLS";
import apiClient from "./client";
import { errorResolver } from "helpers/resolvers/apiResolvers";
import { GetUsersRequestTypes } from "helpers/types/users.types";

const users = {
  getUsersList(payload: GetUsersRequestTypes) {
    return apiClient
      .post(API.GET_USERS_LIST, payload)
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
  deleteUser(user_id: string) {
    return apiClient
      .post(API.DELETE_USER, {
        action: "delete_user",
        user_id,
      })
      .then((res) => {
        return res.data;
      })
      .catch(errorResolver);
  },
};
export default users;
