import { ReactComponent as NoDataFoundIcon } from "assets/images/nodata.svg";
import { twMerge } from "tailwind-merge";

type Props = {
  containerClassName?: string;
  svgClassName?: string;
  noDataText?: string;
};

const NoDataFound = ({
  containerClassName,
  svgClassName,
  noDataText = "No Data Found",
}: Props) => {
  return (
    <div
      className={twMerge(
        "flex flex-col items-center justify-center h-full",
        containerClassName
      )}
    >
      <NoDataFoundIcon className={twMerge("w-30 h-30", svgClassName)} />
      <p className="text-[#1D1E1B] mt-7">{noDataText}</p>
    </div>
  );
};

export default NoDataFound;
