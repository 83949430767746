// General
export const UPLOAD_IMAGE = "/general/image/upload";
export const GET_SETTINGS = "/general/setting/get";

// Auth
export const LOGIN = "/admin/login";
export const FORGOT_PASSWORD = "/admin/forgot-password";
export const RESET_PASSWORD = "/admin/reset-password";

// Dashbaord
export const GET_DASHBOARD_COUNTS = "/admin/dashboard/count";
export const GET_GRAPH_DATA = "/admin/graph-count/get";

//Users
export const GET_USERS_LIST = "/admin/users/get-list";
export const DELETE_USER = "user/manage_user?is_admin=true";

// Applications
export const GET_APPLICATIONS_LIST = "admin/application/get-list";
export const GET_APPLICATION_DETAILS = "admin/application/get-details";

export const VIEW_AS_USER = "/admin/view-as-user";
