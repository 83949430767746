export const NAVS = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },
  {
    name: "Users",
    path: "/users",
  },
  {
    name: "Applications",
    path: "/applications",
  },
];
