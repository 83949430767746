import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const ApplicationDetails = lazy(() => import("modules/application-details"));
export default function ApplicationDetailsPage() {
  return (
    <SuspenseWrapper>
      <ApplicationDetails />
    </SuspenseWrapper>
  );
}
