// my-theme.ts
const myTheme = {
  font: {
    primary: `"Manrope", sans-serif`,
    secondary: `'Manrope', sans-serif`,
  },
  colors: {
    primary: "var(--primary-color)",
    secondary: "var(--secondary-color)",
    primaryDark: "#7a12e5",
    authBg: "#D7D7D7",
    grayBg: "#F6F5FF",
    lightGray: "#F1EFF7",
    darkGray: "#444444",
    gray1: "#DBD9E1",
    gray2: "#DDDDDD",
    gray3: "#888",
    gray4: "#D9D9D9",
    white: "#fff",
    black: "#000",
  },

  statusColors: {
    green: {
      bg: "#EAFFF1",
      color: "#32B155",
    },
    yellow: {
      bg: "#FFF5DD",
      color: "#E3891E",
    },
    gray: {
      bg: "#E7E7E7",
      color: "#5F5F5F",
    },
    red: {
      bg: "#FFEAEA",
      color: "#FF0505",
    },
    blue: {
      bg: "#EAF2FF",
      color: "#283EFF",
    },
    pink: {
      bg: "#FFEAFC",
      color: "#FF28A9",
    },
    violet: {
      bg: "#F9EAFF",
      color: "#C728FF",
    },
  },
};

export { myTheme };
