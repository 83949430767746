import React from "react";

function useTableScrollFade(tableData: any) {
  const onWindowResize = React.useCallback(() => {
    if (tableData) {
      const div = document.getElementsByClassName("MuiTableContainer-root")[0];
      if (div) {
        const hs = div.scrollWidth > div.clientWidth;
        const elemFaded = document.getElementById("faded-effect");
        if (elemFaded) {
          if (hs) {
            elemFaded.classList.add("table-faded-effect");
          } else {
            elemFaded.classList.remove("table-faded-effect");
          }
        }
      }
    }
  }, [tableData]);

  React.useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [onWindowResize]);

  React.useEffect(() => {
    onWindowResize();
  }, [onWindowResize]);

  return null;
}

export default useTableScrollFade;
