import { ClickAwayListener, Fade } from "@mui/material";

type Props = {
  toggle: () => void;
  onLogout: () => void;
};

const ProfileMenu = ({ toggle, onLogout }: Props) => {
  return (
    <ClickAwayListener onClickAway={toggle}>
      <Fade
        in
        className="absolute top-14 right-0 z-[9] bg-[#fff] rounded-lg drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] transition ease-in-out duration-300"
      >
        <div className="options rounded-lg">
          <div
            className="font-medium text-[#DE1717] rounded-lg px-4 py-3 hover:text-lightblack"
            onClick={onLogout}
          >
            Logout
          </div>
        </div>
      </Fade>
    </ClickAwayListener>
  );
};

export default ProfileMenu;
