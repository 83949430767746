import axios, { AxiosError, AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { getToken } from "helpers/storage";

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  if (error.response?.status === 401) {
    localStorage.removeItem("token");
    toast.error("Session expired. Please login again.");
    window.location.replace("/login");
  }
  return Promise.reject(error);
};

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(onResponse, onResponseError);

export default apiClient;
