import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IconButton, TablePagination } from "@mui/material";
import { Button } from "components/buttons";
import SearchBox from "components/SearchBox";
import EnhancedTableHead from "components/table/EnhancedTableHead";
import StyledTable from "components/table/StyledTable";
import DeleteUserPrompt from "components/users/DeleteUserPrompt";
import Loader from "components/loaders/Loader";
import ProfileAvatar from "components/ProfileAvatar";
import useDebounce from "helpers/hooks/useDebounce";
import NoDataFound from "components/NoDataFound";
import users from "helpers/http/users";
import useTableScrollFade from "helpers/hooks/useTableScrollFade";
import { SortOrderTypes } from "helpers/types/general.types";
import { getLocalDate, showError } from "helpers/misc";
import auth from "helpers/http/auth";
import { ACTIVE_USERS_CELLS } from "./TABLE_CELLS";
import { TABLE_RECORDS_PER_PAGE } from "consts/CONFIG";
import { redirectionUrls } from "consts/SWITCH_URLS";
import { ReactComponent as Trash } from "assets/icons/trash.svg";

const ActiveUsers = () => {
  const [order, setOrder] = React.useState<SortOrderTypes>("ASC");
  const [rows, setRows] = React.useState<any>([]);
  const [page, setPage] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [settingUpUserTokenFor, setSettingUpUserTokenFor] = useState<
    string | null
  >(null);
  const [selectedMember, setSelectedMember] = React.useState<{
    userId: string;
    name: string;
    show: boolean;
  }>({
    userId: "",
    name: "",
    show: false,
  });

  const { data, isLoading, isRefetching } = useQuery(
    ["active-users", page, debouncedSearch, order],
    () =>
      users.getUsersList({
        page,
        limit: TABLE_RECORDS_PER_PAGE,
        keyword: debouncedSearch,
        status: "active-users",
        sort: [
          {
            key: "APPLICATION",
            value: order,
          },
        ],
      }),
    {
      keepPreviousData: true,
    }
  );

  useTableScrollFade(data?.data?.userData);

  React.useEffect(() => {
    setPage(1);
  }, [debouncedSearch, order]);

  React.useEffect(() => {
    if (data?.data?.userData) {
      setRows(data?.data?.userData);
    }
  }, [data?.data?.userData]);

  const handleRequestSort = () => {
    setOrder((prev) => (prev === "ASC" ? "DESC" : "ASC"));
  };

  const onPageChange = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleDelete = (item: any) => () => {
    setSelectedMember({
      userId: item.user_id,
      name: item.first_name + " " + item.last_name,
      show: true,
    });
  };

  const handleCloseDeletePrompt = () => {
    setSelectedMember({
      userId: "",
      name: "",
      show: false,
    });
  };

  const onDelete = () => {
    handleCloseDeletePrompt();
  };

  const handleViewAsUser = (user_id: string) => () => {
    if (settingUpUserTokenFor) return;
    setSettingUpUserTokenFor(user_id);
    auth
      .viewAsUser(user_id)
      .then((res) => {
        const token = res?.data?.token;
        const host = window.location.hostname;
        console.log("host: " + host);
        console.log("redirectionUrls[host]: " + redirectionUrls[host]);
        let redirectUrl = redirectionUrls[host];
        if (token && redirectUrl) {
          console.log("redirectUrl: " + redirectUrl);
          window.open(`${redirectUrl}/admin-as-user?auth=${token}`, "_blank");
        }
        setSettingUpUserTokenFor(null);
      })
      .catch((err) => {
        showError(err?.response?.data?.message);
        setSettingUpUserTokenFor(null);
      });
  };

  return (
    <div className="w-full">
      <SearchBox
        className="md:w-[410px] w-full max-w-full"
        placeholder="Search"
        endIcon={isRefetching && <Loader size={24} />}
        onChange={handleSearch}
      />
      <div className="relative mt-4">
        <div id="faded-effect" />
        <StyledTable>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            className="overflow-hidden"
          >
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={ACTIVE_USERS_CELLS}
              order={order}
            />

            <TableBody>
              {rows?.length > 0 &&
                rows.slice(0, TABLE_RECORDS_PER_PAGE).map((row: any) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.user_id}>
                      <TableCell padding="none" className="px-4 w-[20%]">
                        <div className="flex items-center gap-3">
                          <ProfileAvatar
                            imageurl={row.user_image}
                            name={row.first_name + " " + row.last_name}
                            className="h-10 w-10 rounded-full object-cover"
                          />
                          <div className="text-base font-medium">
                            {row.first_name + " " + row.last_name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="px-4 text-base w-[20%]">
                        <a
                          href={`mailto:${row.u_email_id}`}
                          target="blank"
                          className="text-inherit no-underline hover:text-primary-color"
                        >
                          {row.u_email_id}
                        </a>
                      </TableCell>
                      <TableCell className="px-4 text-base w-[15%] font-medium">
                        +{row.phone_number}
                      </TableCell>
                      <TableCell className="px-4 text-base w-[5%] font-medium">
                        {row.application_count?.toLocaleString()}
                      </TableCell>
                      <TableCell className="px-4 text-base w-[5%] font-medium">
                        {row.contact_count?.toLocaleString()}
                      </TableCell>
                      <TableCell className="px-4 text-base w-[15%]">
                        {getLocalDate(row.date_created)}
                      </TableCell>
                      <TableCell className="px-4 w-[10%]">
                        <Button
                          className="min-w-[120px] font-normal"
                          variant="text"
                          onClick={handleViewAsUser(row.user_id)}
                        >
                          {settingUpUserTokenFor === row.user_id ? (
                            <Loader size={20} />
                          ) : (
                            "View as user"
                          )}
                        </Button>
                      </TableCell>
                      <TableCell className="px-4 w-[10%]">
                        <IconButton className="p-2" onClick={handleDelete(row)}>
                          <Trash />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          {data?.data?.total > TABLE_RECORDS_PER_PAGE && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              page={page - 1}
              rowsPerPage={TABLE_RECORDS_PER_PAGE}
              count={data?.data?.total}
              onPageChange={onPageChange}
            />
          )}
        </StyledTable>
      </div>

      {isLoading && <Loader className="flex items-center justify-center" />}

      {!isLoading && !isRefetching && !rows?.length && (
        <NoDataFound
          noDataText="No record found"
          containerClassName="flex flex-col min-h-[65vh] justify-center items-center"
        />
      )}

      <DeleteUserPrompt
        deleteMemberState={selectedMember}
        toggle={handleCloseDeletePrompt}
        onDelete={onDelete}
      />
    </div>
  );
};

export default ActiveUsers;
