import * as APIS from "consts/http/API_URLS";
import apiClient from "./client";

const general = {
  async generateAwsUrl(data: {
    folder: string;
    file_name: string;
    content_type: string;
    contentClass?: string;
  }) {
    return apiClient.post(APIS.UPLOAD_IMAGE, data).then((r) => r.data);
  },
  async getSettings() {
    return apiClient.get(APIS.GET_SETTINGS).then((r) => r.data);
  },
};
export default general;
