import { useMemo } from "react";
import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { SortOrderTypes } from "helpers/types/general.types";

interface EnhancedTableProps {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  rowCount: number;
  order?: SortOrderTypes;

  headCells: {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
    allowSort?: boolean;
    bold?: boolean;
  }[];
}

const Wrapper = styled(TableHead)`
  .MuiTableSortLabel-icon {
    color: var(--primary-color) !important;
  }
`;

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort && onRequestSort(event, property);
    };

  const direction = useMemo(() => {
    if (props.order === "ASC") {
      return "desc";
    } else {
      return "asc";
    }
  }, [props.order]);

  return (
    <Wrapper className="bg-[#EFF1FF] rounded-xl w-full rounded-3xl">
      <TableRow className="border-none">
        {props.headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            className="px-2 first:rounded-l-xl last:rounded-r-xl border-none min-w-[25%]"
          >
            <TableSortLabel
              active={headCell.allowSort}
              direction={direction}
              onClick={createSortHandler(headCell.id)}
              className="px-2 font-semibold text-base text-[#2A2B2F]"
              disabled={!headCell.allowSort}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </Wrapper>
  );
};

export default EnhancedTableHead;
