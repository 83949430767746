import React, { Suspense } from "react";
import PageLoader from "components/loaders/PageLoader";

function SuspenseWrapper({ children }: { children?: React.ReactNode }) {
  return (
    <Suspense
      fallback={
        <div>
          <PageLoader />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}

export default SuspenseWrapper;
