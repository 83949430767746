import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const Error404 = lazy(() => import("modules/error-pages/404"));
export default function Page404() {
  return (
    <SuspenseWrapper>
      <Error404 />
    </SuspenseWrapper>
  );
}
