import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const Users = lazy(() => import("modules/users"));
export default function UsersPage() {
  return (
    <SuspenseWrapper>
      <Users />
    </SuspenseWrapper>
  );
}
