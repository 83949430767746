import { Button, LoadingButton } from "components/buttons";
import UIModal from "components/UIModal";

type Props = {
  logout: () => void;
  isOpen: boolean;
  toggle: () => void;
};

const LogoutPrompt = ({ logout, isOpen, toggle }: Props) => {
  const onLogout = () => {
    logout();
  };

  return (
    <>
      <UIModal show={isOpen} toggle={toggle} title="" maxWidth="sm">
        <div className="text-center text-xl font-medium">
          Are you sure you want to logout?
        </div>
        <div className="flex justify-center mt-6 gap-3">
          <Button variant="outlined" onClick={toggle}>
            No
          </Button>
          <LoadingButton onClick={onLogout}>Yes, logout</LoadingButton>
        </div>
      </UIModal>
    </>
  );
};

export default LogoutPrompt;
