const DRAWER_WIDTH = 275;
const DEFAULT_AVATAR = "/images/default_avatar.png";
const COMPANY_LOGO = "/logo.svg";
const DEFAULT_COMPANY_ICON = "/images/logo-icon.svg";
export const TABLE_RECORDS_PER_PAGE = 10;

export const CONFIG = {
  DRAWER_WIDTH,
  DEFAULT_AVATAR,
  COMPANY_LOGO,
  DEFAULT_COMPANY_ICON,
};
