import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled.div`
  a {
    text-decoration: none;
    position: relative;
    padding-bottom: 10px;
  }
  .active {
    font-weight: bold;
  }
  a::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 2px;
    left: 50%;
    bottom: 0;
    background-color: var(--light-black);
    transition: all ease-in-out 0.2s;
  }
  a:hover::after {
    width: 100%;
    left: 0;
  }
`;

const NavItem = ({
  nav,
}: {
  nav: {
    name: string;
    path: string;
  };
}) => {
  return (
    <StyledLink>
      <NavLink
        className={({ isActive }) =>
          `text-lightblack ${isActive ? "active" : ""}`
        }
        to={nav.path}
      >
        {nav.name}
      </NavLink>
    </StyledLink>
  );
};

export default NavItem;
