export const ROUTES = {
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:hash",
  NOT_FOUND: "/404",
  USERS: "/users",
  APPLICATIONS: "/applications",
  APPLICATION_DETAILS: "/application-details/:id",
  USER_DETAILS: "/user-details/:id",
};
