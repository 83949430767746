import { Navigate, Outlet } from "react-router-dom";
import AuthHeader from "components/header/AuthHeader";
import { getToken } from "helpers/storage";

function AuthLayout() {
  const isLoggedIn = getToken();
  return (
    <>
      <AuthHeader />
      {isLoggedIn ? <Navigate to="/home" /> : <Outlet />}
    </>
  );
}

export default AuthLayout;
