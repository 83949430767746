import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import PageLoader from "components/loaders/PageLoader";
import general from "helpers/http/general";
import { showError } from "helpers/misc";
import { getToken, removeToken } from "helpers/storage";
import { SettingsType } from "helpers/types/general.types";

interface AuthContextType {
  signin: (token: string) => void;
  signout: () => void;
  settingsData?: SettingsType | null;
}
const AuthContext = React.createContext<AuthContextType>({
  signin: () => {},
  signout: () => {},
  settingsData: null,
});

function AuthProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isBootstaping, setIsBootstraping] = React.useState(true);

  React.useEffect(() => {
    // check for token initally
    const token = getToken();

    if (!token) {
      setIsBootstraping(false);
    }
    setTimeout(() => {
      setIsBootstraping(false);
    }, 1500);
  }, []);

  const { data: settings } = useQuery(["get-settings"], general.getSettings, {
    enabled: !!getToken(),
    onError: (err) => showError(err + ""),
    retry: 0,
  });

  const settingsData = useMemo(() => {
    if (settings) return settings?.data;
  }, [settings]);

  const signin = React.useCallback(
    async (token: string) => {
      try {
        await localStorage.setItem("token", token);
        queryClient.invalidateQueries(["get-user-profile"]);
        navigate("/dashboard");
      } catch (err) {
        console.log(err);
      }
    },
    [navigate, queryClient]
  );

  const signout = React.useCallback(() => {
    removeToken();
    queryClient.setQueryData(["get-user-profile"], null);
    navigate("/login");
  }, [navigate, queryClient]);

  const value = React.useMemo(
    () => ({ signin, signout, settingsData }),
    [signin, signout, settingsData]
  );

  if (isBootstaping) {
    return <PageLoader />;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth };
