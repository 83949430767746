import RequireAuth from "app-router/RequireAuth";
import Header from "components/header/Header";
import { Outlet } from "react-router-dom";

const PageLayout = () => {
  return (
    <RequireAuth>
      <>
        <Header />
        <div className="flex flex-col bg-secondary-color fullscreen md:px-10 md:py-8 p-4 pt-8">
          <Outlet />
        </div>
      </>
    </RequireAuth>
  );
};

export default PageLayout;
