import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const UserDetails = lazy(() => import("modules/user-details"));
export default function UserDetailsPage() {
  return (
    <SuspenseWrapper>
      <UserDetails />
    </SuspenseWrapper>
  );
}
