import { lazy } from "react";
import SuspenseWrapper from "components/SuspenseWrapper";

const Applications = lazy(() => import("modules/applications"));
export default function ApplicationsPage() {
  return (
    <SuspenseWrapper>
      <Applications />
    </SuspenseWrapper>
  );
}
